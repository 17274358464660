import Sheet from '@mui/joy/Sheet';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import SheetPanel from '../components/App/sheetPanel';
import { useNavigate } from 'react-router-dom';

const PgHome = () => {
    const navigate = useNavigate()
    return (   
        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
            <SheetPanel>
                <div style={{display: 'flex', gap: '1rem', flexWrap: 'wrap'}}>
                    <h4 style={{width: '100%'}}>Juntos desenvolvendo soluções</h4>
                    <p  style={{textAlign: 'justify'}}>
                        Somos uma fábrica de software, atuamos com profissionais
                        especializados em tecnologias diversas, desenvolvemos soluções para aplicativos, sites, webapps e api's sob demanda.
                    </p>
                    <p style={{textAlign: 'justify'}}>
                        Para nós, o importante não é somente entregar o produto final com qualidade, mas 
                        também entregar uma arquitetura completa em ambas as camadas da solução.
                    </p>
                </div>
            </SheetPanel>

            <Sheet
                sx={{
                    maxWidth: '500px',
                    mx: 2, // margin left & right
                    my: 4, // margin top & botom
                    py: 3, // padding top & bottom
                    px: 2, // padding left & right
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 'sm',
                    boxShadow: 'sm'
                }}
            >
                <div style={{display: 'flex', gap: '1rem', flexWrap: 'wrap', justifyContent: 'center'}}>
                    <h4 style={{width: '100%'}}>Transforme sua ideia em realidade</h4>
                    <p style={{width: '100%', textAlign: 'justify'}}>
                        Converse com nossos analistas e dê o primeiro passo!
                    </p>
                    <Button
                        variant='outlined'
                        color='warning'
                    >
                        Entrar em contato
                    </Button>
                </div>
            </Sheet>

            <Sheet
                sx={{
                    maxWidth: '500px',
                    mx: 2, // margin left & right
                    my: 4, // margin top & botom
                    py: 3, // padding top & bottom
                    px: 2, // padding left & right
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 'sm',
                    boxShadow: 'sm',
                }}
            >
                <div style={{display: 'flex', gap: '1rem', flexWrap: 'wrap', justifyContent: 'center'}}>
                    <h4 style={{width: '100%'}}>Precisando de um profissional?</h4>
                    <p style={{width: '100%', textAlign: 'justify'}}>
                        Contrate um de nossos profissionais para o seu projeto
                    </p>
                    <Button
                        variant='outlined'
                        color='info'
                    >
                        Encontrar profissionais
                    </Button>
                </div>
            </Sheet>

            {/*  */}

            <SheetPanel>
                <div style={{display: 'flex', gap: '1rem', flexWrap: 'wrap', justifyContent: 'center'}}>
                    <h4 style={{width: '100%'}}>Soluções proprietárias</h4>
                    <p style={{width: '100%', textAlign: 'justify'}}>
                        Oferecemos também algumas soluções prontas, são produtos que já estão no mercado e podem atender suas necessidades, conheça algumas das principais:
                    </p>
                    
                    <Card
                        orientation="horizontal"
                        variant="outlined"
                        onClick={() => navigate('/frota')}
                        sx={{ maxWidth: 320, bgcolor: 'background.body', cursor: 'pointer' }}
                    >
                        <CardContent sx={{ px: 1 }}>
                            <div style={{ 
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <img
                                    style={{ height: '100px' }}
                                    src="/static/frota.png"
                                    loading="lazy"
                                    alt=""
                                />
                            </div>
                        </CardContent>

                        <CardContent sx={{ px: 1 }}>
                            <Typography fontWeight="md" textColor="warning.plainColor" mb={0.5}>
                                Frota: Motoboys
                            </Typography>
                            <Typography level="body2">Controle de entregas e motoboys para seu delivery</Typography>
                        </CardContent>
                    </Card>
                    
                </div>
            </SheetPanel>
        </div>
    );
}

export default PgHome