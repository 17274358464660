import { Route, Routes, BrowserRouter } from "react-router-dom";
import Header from "./components/App/header";
import PgHome from "./pages";
import PgFrota from "./pages/frota";

const Rotas = () => {
    // const {token, setToken} = useAutenticacao();

    // if(!token!) {
    //     return <Autenticacao setToken={setToken} />
    // };

    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path={'/home'} element={<PgHome />} />

                <Route path={'/'} element={<PgFrota />} />
                <Route path={'/contato'} element={<PgFrota />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Rotas;