import SheetPanel from "../../components/App/sheetPanel";
import Button from '@mui/joy/Button';
import styles from "./styles.module.scss";
import { AiOutlineArrowDown } from "react-icons/ai";
import { FcDeployment, FcDonate, FcIdea, FcPrint, FcShop, FcTwoSmartphones } from "react-icons/fc";
import { FaDownload, FaInstagram, FaLinkedin, FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { useQuery } from "react-query";
import useAPI from "../../components/App/useAPI";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface ICliente {
    NOME: string;
    IMAGEM: string;
    TEXTO: string;
    INSTAGRAM: string;
}

const PgFrota = () => {
    const API = useAPI();
    const qryVendas = useQuery(
        'qryVendas',
        (
            () => API.get(`empresa/buscar/qtdEntregasGlobal`, '', false)
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 3
        }
    );  
    const clientes : ICliente[] = [
        {
            NOME: 'De Açaí',
            IMAGEM: 'static/clientes/deacai.jpg',
            TEXTO: 'Desde que começamos usar o app frota ficou fácil controlar a demanda dos entregadores, além de agilizar o fechamento do caixa.',
            INSTAGRAM: '@deacai_delivery'
        },
        {
            NOME: 'Bella Massa Pizzaria',
            IMAGEM: 'static/clientes/bellamassa.jpg',
            TEXTO: 'Reduzimos o custo de impressão de pedidos, pois agora não precisamos mais nos preocupar com os papeis para contabilizar o dia, além de agilizar o nosso processo operacional.',
            INSTAGRAM: '@bellamassacolatina'
        }
    ]
    const [indiceCliente, setIndiceCliente] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const intervalId = setInterval(() => {
          // Atualiza o índice do cliente a cada 5 segundos
            setIndiceCliente((prevIndice) => (prevIndice + 1) % clientes.length);
        }, 8000);
    
        // Limpa o intervalo quando o componente é desmontado
        return () => clearInterval(intervalId);
      }, []); //eslint-disable-line
    
    return (
        <>
            <Button 
                variant="soft"
                color="info"
                style={{display: 'flex', position: "fixed", right: '0', bottom: '0', zIndex: '999', marginRight: '2rem', marginBottom: '2rem'}}
                onClick={() => {
                    const titulo = document.getElementById("contato");
                    window.scrollTo({
                        top: titulo!.offsetTop - 50,
                        behavior: "smooth"
                    });
                }}
            >Solicite demonstração</Button>

            <div className={styles.wrapperPrincipal}>
                <div className={styles.titulo}>
                    <h1>Pode chamar de frota</h1>
                </div>

                <div className={styles.descricao}>
                    <h2>Aumente o alcance do seu Delivery!</h2>
                    {/* <h2>Controlar a logística do delivery do seu estabelecimento é trabalhoso?</h2> */}
                    
                    <h3>
                        Os apps geralmente fazem muitas vendas, mas gerenciar tudo pode ser um desafio pois não oferecem nenhum suporte para controlar a logística das entregas. Comigo você pode integrar o serviço de múltiplos apps de delivery, tendo um gerenciamento centralizado e eficiente de suas vendas.
                    </h3>

                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Button 
                            onClick={() => {
                                const titulo = document.getElementById("institucional");
                                window.scrollTo({
                                    top: titulo!.offsetTop - 50,
                                    behavior: "smooth"
                                });
                            }}  
                            size="lg"
                        ><AiOutlineArrowDown style={{zoom: 1.2}} /></Button>
                    </div>
                </div>

                <div className={styles.imagem}>
                    <img className="rotacao" alt="phone" src="/static/holdphonefrota.png" />
                </div>
            </div>

            <div className={styles.wrapperCards} >
                <h1 id="institucional" className={styles.titulo}>O que temos a oferecer?</h1>
                
                <div className={styles.areaCards}>
                    <SheetPanel className={styles.card}>
                        <FcShop className={styles.icon} />
                        <h3 className={styles.titulo}>Integração de Pedidos</h3>
                        <p className={styles.descricao}>
                            Agora você pode vender por quantas plataformas de delivery você quiser! Cada venda será integrada no Frota automaticamente, proporcionando uma centralização eficiente das vendas em um único local.
                        </p>                    
                    </SheetPanel>

                    <SheetPanel className={styles.card}>
                        <FcDeployment className={styles.icon} />
                        <h3 className={styles.titulo}>Gerenciamento de Entregas</h3>
                        <p className={styles.descricao}>
                            Faça o despacho das entregas para cada motoboy, obtenha acompanhamento em tempo real, controle dos recebimentos e das taxas instantaneamente! A eficiência pode ser ampliada ainda mais com o app dedicado para os entregadores.
                        </p>                    
                    </SheetPanel>

                    <SheetPanel className={styles.card}>
                        <FcDonate className={styles.icon} />
                        <h3 className={styles.titulo}>Fechamento de Caixa</h3>
                        <p className={styles.descricao}>
                            Não perca mais tempo no final do dia contabilizando o caixa em cada plataforma de delivery ou no Excel para pagar seus entregadores. Tenha tudo em tempo real! Além disso, é possível ter uma contabilização detalhada por cada forma de pagamento e relatórios diários.
                        </p>                    
                    </SheetPanel>
                
                    <SheetPanel className={styles.card}>
                        <FcPrint className={styles.icon} />
                        <h3 className={styles.tituloCard}>Módulo de pedidos e mesas</h3>
                        <p className={styles.descricao}>
                            Além de toda nossa especialidade em gerenciamento de entregas, também disponibilizamos um módulo de pedidos e controle de mesas sem custos adicionais. Disponibilize para seu cliente um cardápio online com possibilidade de fazer pedidos, módulo de garçons, qrcode nas mesas ou até mesmo pontos de autoatendimento.
                        </p>                    
                    </SheetPanel>

                    <SheetPanel className={styles.card}>
                        <FcTwoSmartphones className={styles.icon} />
                        <h3 className={styles.tituloCard}>App opcional para entregadores</h3>
                        <p className={styles.descricao}>
                            Com o app para entregadores é possivel ampliar a eficiência, com os próprios entregadores recebendo as entregas a fazer na palma da mão. Além de vários atalhos para facilitar o trabalho dele, como atalho para o contato do cliente, GPS e pagamentos.
                        </p>                    
                    </SheetPanel>

                    <SheetPanel className={styles.card}>
                        <FcIdea className={styles.icon} />
                        <h3 className={styles.tituloCard}>Melhorias e suporte</h3>
                        <p className={styles.descricao}>
                            O Frota está em constante crescimento, com adição de módulos novos e integrações totalmente sem custos adicionais, além de contar com um suporte especializado 7 dias por semana.
                        </p>                    
                    </SheetPanel>
                </div>
            </div>

            <div className={styles.wrapperContato}>
                <h1 id="contato" className={styles.titulo}>Vamos ao que interessa?</h1>
                
                <div className={styles.areaCards}>
                    <SheetPanel className={styles.card}>
                        <h3 className={styles.tituloCard} style={{width: '100%', display: 'flex', justifyContent: 'center'}}>Comece a usar o app hoje mesmo!</h3>
                        <p style={{textAlign: "center", maxWidth: "25rem"}}>
                            Entre em contato com nosso time comercial<br />
                            Veja como gerenciar o seu estabelecimento pode ser descomplicado!
                            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                                {/* <h3 style={{width: '100%', textAlign: 'center'}}>R$ 100,00 / mês</h3> */}
                                <Button
                                    style={{marginTop: '1rem', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}
                                    color='success'

                                    onClick={() => {
                                        navigate('/contato');
                                        window.open(`https://api.whatsapp.com/send?phone=5527996957014&text=Olá%20gostaria%20de%20saber%20mais%20sobre%20o%20app%20frota`);
                                    }}
                                >
                                    <FaWhatsapp style={{zoom: '1.3'}} /> Conversar no Whatsapp
                                </Button>
                            </div>
                            <br/>
                            <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                                <p><FaPhoneAlt /> (27) 99695-7014</p>
                                <p><MdOutlineEmail style={{zoom: '1.3'}} /> contato@escopo.app</p>
                            </div>
                        </p>                    
                    </SheetPanel>
                </div>
            </div>

            <div className={styles.wrapperComercial}>
                <h1 id="comercial" className={styles.titulo}>{qryVendas.isSuccess ? qryVendas.data["total"] : 'Mais de 35 mil'} vendas processadas no frota</h1>
                <br />
                
                <h3 style={{textAlign: 'center'}}>hamburgueria • pizzaria • açaídeira • sorveteria • distribuidoras • bares • restaurantes</h3>

                <SheetPanel className={styles.card}>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <img style={{maxWidth: '7rem', borderRadius: '7rem'}} src={clientes[indiceCliente].IMAGEM} alt="logoCliente" />
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                        <h3 className={styles.tituloCard} style={{display: 'flex', justifyContent: 'center'}}>{clientes[indiceCliente].NOME}</h3>
                        <h3 style={{display: 'flex', justifyContent: 'center', color: '#ecba66'}}>★★★★★</h3>
                    </div>
                    <p style={{textAlign: "justify", maxWidth: "25rem", height: "10rem"}}>
                        {clientes[indiceCliente].TEXTO}
                        <br/><br/>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><FaInstagram fontSize={'1.3rem'} /> {clientes[indiceCliente].INSTAGRAM}</div>
                    </p>                    
                </SheetPanel>
            </div>

            <div className={styles.wrapperFooter}>                
                <div style={{display: 'flex', width: '8rem', alignItems: 'center', justifyContent: 'space-around'}}>
                    <a href="https://www.instagram.com/frota_delivery/"><FaInstagram fontSize={'1.3rem'} cursor={'pointer'} /></a> 
                    <a href="https://www.linkedin.com/company/97889235/"><FaLinkedin fontSize={'1.3rem'} cursor={'pointer'} /></a>
                </div>
                <h5 style={{textAlign: 'center'}}>© Copyright 2024 - Frota</h5>
                <h5 style={{textAlign: 'center'}}>Escopo Desenvolvimento de Software LTDA</h5>
                <h6 style={{textAlign: 'center'}}>CNPJ 51.237.551/0001-04 / Av Maria Vitali Vago, Fazenda Vitali, Colatina/ES</h6>
                <a style={{textAlign: 'center'}} href="static/manualIntegracaoFrota.pdf"><FaDownload /> Manual Integrações</a>
            </div>
        </>
    );
}

export default PgFrota;