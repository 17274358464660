import Layout from "../MUI/layout";
import ModeToggle from "../MUI/modeToggle";

const Header = () => {
    return (
        <Layout.Header
            sx={{
                fontSize: '1.3rem'
            }}
        >
            {`{ escopo }`}

            <ModeToggle />
        </Layout.Header>
    )
};

export default Header;