import Box, { BoxProps } from '@mui/joy/Box';

function Header(props: BoxProps) {
    return (
      <Box
        component="header"
        className="Header"
        {...props}
        sx={[
          {
            p: 2,
            gap: 2,
            bgcolor: 'background.surface',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gridColumn: '1 / -1',
            borderBottom: '1px solid',
            borderColor: 'divider',
            position: 'sticky',
            top: 0,
            zIndex: 1100,
          },
          ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
        ]}
      />
    );
}

function SideNav(props: BoxProps) {
    return (
      <Box
        component="nav"
        className="Navigation"
        {...props}
        sx={[
          {
            p: 2,
            bgcolor: 'background.surface',
            borderRight: '1px solid',
            borderColor: 'divider',
            display: {
              xs: 'none',
              sm: 'initial',
            },
          },
          ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
        ]}
      />
    );
}

function SidePane(props: BoxProps) {
    return (
      <Box
        className="Inbox"
        {...props}
        sx={[
          {
            bgcolor: 'background.surface',
            borderRight: '1px solid',
            borderColor: 'divider',
            display: {
              xs: 'none',
              md: 'initial',
            },
          },
          ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
        ]}
      />
    );
}

function Main(props: BoxProps) {
    return (
      <Box
        component="main"
        className="Main"
        {...props}
        sx={[{ p: 2 }, ...(Array.isArray(props.sx) ? props.sx : [props.sx])]}
      />
    );
}

export default {
  Header,
  SideNav,
  SidePane,
  Main,
};