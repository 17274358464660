import Rotas from './routes';
import '@fontsource/public-sans';
import { CssVarsProvider } from '@mui/joy/styles';
import { CssBaseline } from '@mui/joy';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryDefault = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			cacheTime: 300000, // Cache 5 miuntos
			retryDelay: 5000, // 5 Segundos para dar um retry em caso de erro
		},
	},
});

function App() {
	return (
		<QueryClientProvider client={queryDefault}>
			<CssVarsProvider defaultMode="system">
				<CssBaseline />
				
				<main>
					<Rotas />
				</main>
			</CssVarsProvider>
		</QueryClientProvider>
	);
}

export default App;
