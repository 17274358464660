import Sheet from '@mui/joy/Sheet';

const SheetPanel = (props: any) => {
    return (
        <Sheet
            sx={{
                maxWidth: '900px',
                mx: 2, // margin left & right
                my: 4, // margin top & botom
                py: 3, // padding top & bottom
                px: 2, // padding left & right
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRadius: 'sm',
                boxShadow: 'sm'
            }}
            {...props}
        >
            {props.children}
        </Sheet>
    )
}

export default SheetPanel;